import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ApiService } from '@cation/core/services/api/api.service';
import { ApiHook } from '@cation/core/services/api/api-hook';
import { MatDialog } from '@angular/material/dialog';
import { SendMailComponent } from './send-mail/send-mail.component';
import { LogService } from '@cation/core/services/log/log.service';
import { ConnectUserListItemModel } from '@cation/core/models/connect-user-list-item.model';
import { ConnectService } from '@cation/core/services/amazonconnect/connect.service';
@Component({
  selector: 'ctn-amazon-connect-send-mail',
  templateUrl: './amazon-connect-send-mail.component.html',
  styleUrls: ['./amazon-connect-send-mail.component.scss'],
})
export class AmazonConnectSendMailComponent {
  public user: any = {};
  constructor(
    private api: ApiService,
    public apiHook: ApiHook,
    private dialog: MatDialog,
    private logService: LogService,
    public connectservice: ConnectService
  ) {}
  displayedColumns: string[] = ['Username', 'Firstname', 'Lastname', 'Email'];
  dataSource = new MatTableDataSource<ConnectUserListItemModel>();
  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  openManageMailResponses($event: MouseEvent) {
    $event.preventDefault();
    $event.stopPropagation();

    this.dialog.open(SendMailComponent, { disableClose: true });
  }
  async AmazonListUsers() {
    const data: any = await this.api.amazonListUsers().toPromise();
    console.log('AmazonListUsers', data);
    this.logService.log('AmazonListUsers:data', data);
    this.dataSource = data;
    this.connectservice.setData(this.dataSource);
  }
}
