<div class="review-user-details-view">
  <ngx-loading [show]="isLoading"></ngx-loading>

  <section *ngIf="user" class="card-group mb-2">
    <div class="card">
      <div class="card-header p-1">
        {{ 'ADMIN_REVIEW_USER.USER_NAME_TITLE' | translate }}
      </div>
      <div class="card-body p-1">{{ user.username }}</div>
    </div>
    <div class="card">
      <div class="card-header p-1">
        {{ 'ADMIN_REVIEW_USER.KEYWORDS_TITLE' | translate }}
      </div>
      <div class="card-body p-1">{{ user.keywords.join(', ') }}</div>
    </div>
    <div class="card">
      <div class="card-header p-1">
        {{ 'ADMIN_REVIEW_USER.LOCALES_TITLE' | translate }}
      </div>
      <div class="card-body p-1">{{ user.locales.join(', ') }}</div>
    </div>
    <div class="card" *ngIf="user?.cmsUserData">
      <div class="card-header p-1">
        {{ 'ADMIN_REVIEW_USER.CMS_INTEGRATIONS_TITLE' | translate }}
      </div>
      <div class="card-body p-1">{{ cmsIntegrations }}</div>
    </div>
  </section>

  <hr />

  <section *ngIf="user">
    <mat-tab-group
      class="overflow-hidden"
      [selectedIndex]="selectedTab.value"
      (selectedIndexChange)="selectedTab.setValue($event)"
    >
      <!--<mat-tab>
        <ng-template mat-tab-label>
          <fa-icon [icon]="faHistory"></fa-icon> &nbsp;
          {{ 'ADMIN_REVIEW_USER.CONVERSATIONS_HISTORY.TITLE' | translate }}
        </ng-template>
        <div class="d-flex row px-3">
          <table mat-table [dataSource]="dataSource" [@listFadeInOut]="dataSource.data.length">
            <ng-container matColumnDef="createdAt">
              <th mat-header-cell *matHeaderCellDef>
                {{ 'ADMIN_REVIEW_USER.CONVERSATIONS_HISTORY.CREATED_AT_COLUMN_TITLE' | translate }}
              </th>
              <td mat-cell *matCellDef="let element" class="ps-2">
                {{ element.createdAt | date: 'dd MMM yyyy' }}
              </td>
            </ng-container>

            <ng-container matColumnDef="intentsTriggered">
              <th mat-header-cell *matHeaderCellDef>
                {{ 'ADMIN_REVIEW_USER.CONVERSATIONS_HISTORY.INTENTS_COLUMN_TITLE' | translate }}
              </th>
              <td mat-cell *matCellDef="let element" [matTooltip]="element.intentsTriggered">
                {{ element.intentsTriggered }}
              </td>
            </ng-container>

            <ng-container matColumnDef="caseId">
              <th mat-header-cell *matHeaderCellDef>
                {{ 'ADMIN_REVIEW_USER.CONVERSATIONS_HISTORY.CASE_ID_COLUMN_TITLE' | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.caseId || '-' }}
              </td>
            </ng-container>

            <ng-container matColumnDef="channel">
              <th mat-header-cell *matHeaderCellDef>
                {{ 'ADMIN_REVIEW_USER.CONVERSATIONS_HISTORY.CHANNEL_COLUMN_TITLE' | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.channel }}
              </td>
            </ng-container>

            <ng-container matColumnDef="actions" stickyEnd>
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let element" class="px-2">
                <button
                  *ngIf="element.status === 'Closed'"
                  class="btn btn-sm btn-outline-primary btn-action p-0"
                  (click)="openCustomerHistory(element.id)"
                >
                  <mat-icon [matTooltip]="'BUTTON.VIEW' | translate">pageview</mat-icon>
                </button>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>

          <mat-paginator [pageSize]="10" showFirstLastButtons></mat-paginator>
        </div>
      </mat-tab> UI_CODE_CLEANUP -->
      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon>sports_esports</mat-icon> &nbsp; {{ 'LIST_ACHIEVEMENTS.TITLE' | translate }} &nbsp;
          <button
            *ngIf="selectedTab.value === 0"
            mat-icon-button
            color="primary"
            (click)="onAddAchievement()"
            [title]="'ADMIN_REVIEW_USER.ADD_ACHIEVEMENT_BUTTON_TEXT' | translate"
          >
            <mat-icon>add_box</mat-icon>
          </button>
        </ng-template>
        <app-achievements-list [achievements]="achievements"></app-achievements-list>
      </mat-tab>
      <!--<mat-tab>
        <ng-template mat-tab-label>
          <mat-icon>search</mat-icon> &nbsp; {{ 'CONVERSATION_SEARCH.TITLE' | translate }} &nbsp;
        </ng-template>
        <app-similar-cases-view [withFilters]="true" [user]="user"></app-similar-cases-view>
      </mat-tab> UI_CODE_CLEANUP -->
      <mat-tab [label]="'ADMIN_REVIEW_USER.STATISTICS_TITLE' | translate">
        <app-my-statistics2
          [isActive]="selectedTab.value === 1"
          [title]="'ADMIN_REVIEW_USER.STATISTICS_TITLE'"
          [userId]="user.cognitoId"
        >
        </app-my-statistics2>
      </mat-tab>
    </mat-tab-group>
  </section>

  <div class="alert alert-danger pt-2" *ngIf="errorMessage">
    {{ errorMessage }}
  </div>
</div>
