<ngx-loading [show]="apiHook.isLoading()"></ngx-loading>

<mat-card class="ctn-card">
  <mat-card-content>
    <form class="ctn-form col-xl-12" #starsForm="ngForm">
      <h4 class="ctn-h3 ctn-margin">
        <mat-icon>grade</mat-icon>
        {{ 'ADMIN_GAMIFICATION_SETTINGS.STARS_FORM.TITLE' | translate }}
      </h4>

      <hr />

      <section [@listFadeInOut]="starsCount">
        <mat-form-field *ngFor="let item of starsWorth | keyvalue; trackBy: trackByFn" class="ctn-form-field">
          <input
            matInput
            required
            name="star_{{ +item.key }}"
            type="number"
            [placeholder]="''.padEnd(+item.key, '★')"
            [(ngModel)]="starsWorth[item.key]"
          />
        </mat-form-field>
      </section>

      <hr />
    </form>

    <div class="row px-3">
      <div>
        <form [formGroup]="levelsFormGroup" class="ctn-form col-lg-6">
          <h4 class="ctn-h3 ctn-margin">
            <mat-icon>call_made</mat-icon>
            {{ 'ADMIN_GAMIFICATION_SETTINGS.LEVELS_FORM.TITLE' | translate }}
            <button
              *ngIf="!levels.length"
              mat-icon-button
              color="primary"
              type="button"
              [title]="'ADMIN_GAMIFICATION_SETTINGS.LEVELS_FORM.ADD_BUTTON_TEXT' | translate"
              (click)="onAddLevel()"
            >
              <mat-icon>add_box</mat-icon>
            </button>
          </h4>

          <hr />

          <section *ngIf="levels" [@listFadeInOut]="levels.length">
            <div *ngFor="let level of levels; let i = index; first as isFirst; last as isLast" class="ctn-level-form">
              <mat-form-field class="ctn-form-field">
                <input
                  matInput
                  required
                  name="level-name-{{ i }}"
                  placeholder="Level name"
                  [formControl]="$any(levelsFormGroup).get(level.id).get('name')"
                />
              </mat-form-field>
              <mat-form-field class="ctn-form-field">
                <input
                  matInput
                  required
                  name="level-points-{{ i }}"
                  type="number"
                  placeholder="Level points"
                  (change)="onPointsChange(level.id)"
                  [formControl]="$any(levelsFormGroup).get(level.id).get('points')"
                  [min]="isFirst ? 0 : levelsFormGroup.get('' + (+level.id - 1)).get('points').value + 1"
                  [max]="isLast ? undefined : levelsFormGroup.get('' + (+level.id + 1)).get('points').value - 1"
                />
              </mat-form-field>
              <div
                class="level-icon"
                [class.level-icon_without_button]="levelsFormGroup.get(level.id).get('icon').value"
              >
                <app-image-picker
                  keyPrefix="settings"
                  [image]="levelsFormGroup.get(level.id).get('icon').value"
                  (onImageSelect)="onLevelIconSelect(level.id, $event)"
                >
                </app-image-picker>
              </div>
              <button
                *ngIf="isLast"
                mat-icon-button
                color="primary"
                type="button"
                [title]="'ADMIN_GAMIFICATION_SETTINGS.LEVELS_FORM.ADD_BUTTON_TEXT' | translate"
                (click)="onAddLevel()"
              >
                <mat-icon>add_box</mat-icon>
              </button>
              <button
                *ngIf="isLast"
                mat-icon-button
                color="primary"
                type="button"
                (click)="onDeleteLevel()"
                [title]="'ADMIN_GAMIFICATION_SETTINGS.LEVELS_FORM.DELETE_BUTTON_TEXT' | translate"
              >
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </section>
        </form>

        <h4 class="ctn-h3 ctn-margin">
          <mat-icon>grade</mat-icon>
          {{ 'ADMIN_GAMIFICATION_SETTINGS.CALL_FORM.TITLE' | translate }}
        </h4>

        <hr />

        <section [@listFadeInOut]="1">
          <mat-form-field class="p-0">
            <input
              matInput
              required
              name="points_per_call"
              type="number"
              [placeholder]="'Points per Call'"
              [(ngModel)]="pointsPerCall"
              [min]="0"
            />
          </mat-form-field>
        </section>

        <hr />

        <h4 class="ctn-h3 ctn-margin">
          <mat-icon>call_made</mat-icon>
          {{ 'ADMIN_GAMIFICATION_SETTINGS.CALL_WORTH_FORM.TITLE' | translate }}
          <button
            *ngIf="!callDurationWorth.length"
            mat-icon-button
            color="primary"
            type="button"
            [title]="'ADMIN_GAMIFICATION_SETTINGS.CALL_WORTH_FORM.ADD_BUTTON_TEXT' | translate"
            (click)="onAddCallDurationWorth()"
          >
            <mat-icon>add_box</mat-icon>
          </button>
        </h4>

        <hr />

        <section *ngIf="callDurationWorth" [@listFadeInOut]="1" class="ctn-call-form">
          <form [formGroup]="callDurationWorthFormGroup">
            <div
              *ngFor="let call of callDurationWorth; let i = index; first as isFirst; last as isLast"
              class="ctn-level-form"
              [class.ctn-level-form-align]="isLast"
            >
              <mat-form-field *ngIf="isLast" class="ctn-form-field">
                <input
                  matInput
                  required
                  name="callDurationWorth-from-{{ i }}"
                  type="number"
                  placeholder="Above"
                  (change)="onFromChange(call.id)"
                  [formControl]="$any(callDurationWorthFormGroup).get(call.id).get('from')"
                  [min]="0"
                />
              </mat-form-field>
              <mat-form-field *ngIf="!isLast" class="ctn-form-field">
                <input
                  matInput
                  required
                  name="callDurationWorth-from-{{ i }}"
                  type="number"
                  placeholder="From"
                  (change)="onFromChange(call.id)"
                  [formControl]="$any(callDurationWorthFormGroup).get(call.id).get('from')"
                  [min]="0"
                />
              </mat-form-field>
              <mat-form-field *ngIf="!isLast" class="ctn-form-field">
                <input
                  matInput
                  required
                  name="callDurationWorth-to-{{ i }}"
                  type="number"
                  placeholder="To"
                  (change)="onToChange(call.id)"
                  [formControl]="$any(callDurationWorthFormGroup).get(call.id).get('to')"
                  [min]="0"
                />
              </mat-form-field>
              <mat-form-field class="ctn-form-field">
                <input
                  matInput
                  required
                  name="callDurationWorth-points-{{ i }}"
                  type="number"
                  placeholder="Points"
                  (change)="oncallDurationWorthPointsChange(call.id)"
                  [formControl]="$any(callDurationWorthFormGroup).get(call.id).get('points')"
                  [min]="0"
                />
              </mat-form-field>
            </div>
          </form>

          <div class="ctn-call-form-buttons">
            <button
              *ngIf="callDurationWorth.length"
              mat-icon-button
              color="primary"
              type="button"
              [title]="'ADMIN_GAMIFICATION_SETTINGS.CALL_WORTH_FORM.ADD_BUTTON_TEXT' | translate"
              (click)="onAddCallDurationWorth()"
            >
              <mat-icon>add_box</mat-icon>
            </button>
            <button
              *ngIf="callDurationWorth.length"
              mat-icon-button
              color="primary"
              type="button"
              (click)="onDeleteCallDurationWorth()"
              [title]="'ADMIN_GAMIFICATION_SETTINGS.CALL_WORTH_FORM.DELETE_BUTTON_TEXT' | translate"
            >
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </section>
      </div>

      <section class="col-lg-6">
        <h4 class="ctn-h3 ctn-margin">
          <mat-icon>settings_input_component</mat-icon>
          {{ 'ADMIN_GAMIFICATION_SETTINGS.ADDITIONAL_SETTINGS.TITLE' | translate }}
        </h4>

        <hr />

        <div class="d-flex flex-column">
          <mat-form-field class="p-0">
            <input
              matInput
              required
              name="team_monthly_point_pool"
              type="number"
              [placeholder]="
                'ADMIN_GAMIFICATION_SETTINGS.ADDITIONAL_SETTINGS.TEAM_MONTHLY_POINT_POOL_PLACEHOLDER' | translate
              "
              [(ngModel)]="teamMonthlyPointPool"
              [min]="0"
            />
          </mat-form-field>

          <div class="d-flex flex-column">
            <mat-form-field class="p-0">
              <input
                matInput
                required
                name="max_points_allowed_in_challenge"
                type="number"
                [placeholder]="
                  'ADMIN_GAMIFICATION_SETTINGS.ADDITIONAL_SETTINGS.MAX_POINTS_ALLOWED_IN_CHALLENGE_PLACEHOLDER'
                    | translate
                "
                [(ngModel)]="maxPointsAllowedInChallenge"
                [min]="0"
              />
            </mat-form-field>
          </div>

          <mat-slide-toggle name="auto-approve-empty-feedback" [(ngModel)]="autoApproveEmptyFeedback">
            {{ 'ADMIN_GAMIFICATION_SETTINGS.ADDITIONAL_SETTINGS.AUTO_APPROVE_EMPTY_FEEDBACK_TOGGLE_TEXT' | translate }}
          </mat-slide-toggle>
        </div>
      </section>
    </div>
    <hr />

    <div class="alert alert-danger pt-2" *ngIf="errorMessage">
      {{ errorMessage }}
    </div>
  </mat-card-content>

  <mat-card-actions class="pt-2">
    <button
      mat-raised-button
      color="primary"
      [title]="'BUTTON.SAVE' | translate"
      class="me-2"
      (click)="onSave()"
      [disabled]="starsForm.invalid || levelsFormGroup.invalid || teamMonthlyPointPool === null"
    >
      <mat-icon>save</mat-icon>
      {{ 'BUTTON.SAVE' | translate }}
    </button>
  </mat-card-actions>
</mat-card>
