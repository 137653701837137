import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import * as moment from 'moment';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '@cation/core/services/api/api.service';
import { LogService } from '@cation/core/services/log/log.service';
import { IUserConversationStatistics } from '@cation/core/types/api';
import { fadeInOut, listFadeInOut } from '@cation/core/animations';
import { AuthHelper } from '@cation/core/auth/auth-helper';

@Component({
  selector: 'app-my-statistics2',
  templateUrl: './my-statistics2.component.html',
  styleUrls: ['./my-statistics2.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [fadeInOut, listFadeInOut],
})
export class MyStatistics2Component implements OnInit {
  _isActive: boolean = false;
  _userId: string = this.authHelper.userProfile.cognitoId;

  isLoading = false;
  isDraw = true;

  statistics: { [prop: string]: IUserConversationStatistics } = {};
  rangeStatistics: { [prop: string]: IUserConversationStatistics[] } = {};

  points: { [prop: string]: object[] } = {};
  stars: { [prop: string]: object[] } = {};
  totalAndDuration: { [prop: string]: object[] } = {};

  colorScheme = {
    domain: ['rgba(252,217,131,1)', 'rgba(72,143,48,1)'],
  };

  selectedTab = new UntypedFormControl(0);
  filterSelectedTab: { [prop: number]: UntypedFormControl } = {
    0: new UntypedFormControl(0),
    1: new UntypedFormControl(0),
    2: new UntypedFormControl(0),
  };
  maxDate = moment().format('YYYY-MM-DD');

  private threeMonthsFilter = {
    startDate: moment().add(-3, 'month').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
  };
  searchFilter: { [prop: string]: { startDate: string; endDate: string } } = {
    '0:3': this.threeMonthsFilter,
    '1:3': this.threeMonthsFilter,
    '2:3': this.threeMonthsFilter,
  };

  defaultStatisticsDate = moment().format('YYYY-MM-DD');
  _statisticsDate: { [prop: string]: string } = {};
  private defaultDateRange = {
    begin: moment().startOf('month').format('YYYY-MM-DD'),
    end: moment().format('YYYY-MM-DD'),
  };
  private threeMonthsDateRange = {
    begin: moment().add(-3, 'month').format('YYYY-MM-DD'),
    end: moment().format('YYYY-MM-DD'),
  };
  _dateRange = {
    '0:3': this.threeMonthsDateRange,
    '1:3': this.threeMonthsDateRange,
    '2:3': this.threeMonthsDateRange,
  };
  private defaultFilter = {
    startDate: this.defaultDateRange.begin,
    endDate: this.defaultDateRange.end,
  };

  todayFilter = 0;
  singleDateFilter = 1;
  thisMonthFilter = 2;
  dateRangeFilter = 3;

  private conversationFeedbackPointsTitle = 'Feedback Points';
  private conversationFeedbackStarsTitle = 'Feedback Stars';
  private conversationPointsTitle = 'Conversation Points';
  private conversationStarsTitle = 'Conversation Stars';
  private numberConversationsTitle = 'Number Of Conversations';
  private durationConversationsTitle = 'Duration Of Conversations';

  @ViewChild('totalTabs', /* TODO: add static flag */ { static: true } as any) totalTabs;
  @ViewChild('byChannelTabs', /* TODO: add static flag */ { static: true } as any) byChannelTabs;
  @ViewChild('byLocaleTabs', /* TODO: add static flag */ { static: true } as any) byLocaleTabs;

  private tabsRef = {
    0: 'totalTabs',
    1: 'byChannelTabs',
    2: 'byLocaleTabs',
  };

  @Input() set isActive(isActive: boolean) {
    if (!this._isActive && isActive) {
      this.delayDraw(true);
      this[this.tabsRef[this.selectedTab.value]].realignInkBar();
    }
    this._isActive = isActive;
  }

  @Input() title: string = 'MY_STATISTICS.TITLE';

  @Input() set userId(userId: string) {
    this._userId = userId;

    this.statistics = {};
    this.rangeStatistics = {};
    this.points = {};
    this.stars = {};
    this.totalAndDuration = {};
    this.selectedTab.setValue(0);
    Object.values(this.filterSelectedTab).map((tab) => tab.setValue(0));
    this.getUserConversationStatistics();
  }

  constructor(
    private apiService: ApiService,
    private logService: LogService,
    public translate: TranslateService,
    private authHelper: AuthHelper
  ) {
    const translateKeys = [
      'MY_STATISTICS.FEEDBACK_POINTS_TITLE',
      'MY_STATISTICS.FEEDBACK_STARS_TITLE',
      'MY_STATISTICS.CONVERSATIONS_POINTS_TITLE',
      'MY_STATISTICS.CONVERSATIONS_STARS_TITLE',
      'MY_STATISTICS.NUMBER_CONVERSATIONS_TITLE',
      'MY_STATISTICS.DURATION_CONVERSATIONS_TITLE',
    ];
    this.translate.stream(translateKeys).subscribe((values) => {
      this.conversationFeedbackPointsTitle = values['MY_STATISTICS.FEEDBACK_POINTS_TITLE'];
      this.conversationFeedbackStarsTitle = values['MY_STATISTICS.FEEDBACK_STARS_TITLE'];
      this.conversationPointsTitle = values['MY_STATISTICS.CONVERSATIONS_POINTS_TITLE'];
      this.conversationStarsTitle = values['MY_STATISTICS.CONVERSATIONS_STARS_TITLE'];
      this.numberConversationsTitle = values['MY_STATISTICS.NUMBER_CONVERSATIONS_TITLE'];
      this.durationConversationsTitle = values['MY_STATISTICS.DURATION_CONVERSATIONS_TITLE'];

      if (this.statistics) {
        this.setData(this.getStatisticsIndex(0, this.todayFilter));
        this.setData(this.getStatisticsIndex(0, this.singleDateFilter));
      }
    });
  }

  ngOnInit() {
    this.getUserConversationStatistics();
  }

  get userId() {
    return this._userId;
  }

  get statisticsDate() {
    if (!this._statisticsDate[this.getStatisticsIndex()]) {
      this._statisticsDate[this.getStatisticsIndex()] = this.defaultStatisticsDate;
    }
    return this._statisticsDate[this.getStatisticsIndex()];
  }

  set statisticsDate(value) {
    this._statisticsDate[this.getStatisticsIndex()] = value;
    this.onStatisticsDateChange();
  }

  get dateRange() {
    if (!this._dateRange[this.getStatisticsIndex()]) {
      this._dateRange[this.getStatisticsIndex()] = this.defaultDateRange;
    }
    return this._dateRange[this.getStatisticsIndex()];
  }

  set dateRange(value) {
    this._dateRange[this.getStatisticsIndex()] = value;
    this.onDateRangeChange();
  }

  async getUserConversationStatistics(isInitial = true) {
    this.isLoading = true;
    try {
      const index = this.getStatisticsIndex();
      const { startDate, endDate } = this.getFilterDates(index);

      const statistics = await this.apiService.getUserConversationStatistics({
        userId: this.userId,
        startDate,
        endDate,
      });

      this.statistics[index] = statistics;
      this.setData();

      this.logService.log('[MyStatisticsComponent getUserConversationStatistics:statistics]', this.statistics);

      if (isInitial) {
        const singleDateIndex = this.getStatisticsIndex(this.selectedTab.value, this.singleDateFilter);
        this.statistics[singleDateIndex] = statistics;
        this.setData(singleDateIndex);

        const byChannelIndex = this.getStatisticsIndex(1, this.todayFilter);
        const byChannelSingleDateIndex = this.getStatisticsIndex(1, this.singleDateFilter);
        this.statistics[byChannelIndex] = statistics;
        this.setData(byChannelIndex);
        this.statistics[byChannelSingleDateIndex] = statistics;
        this.setData(byChannelSingleDateIndex);

        const byLocaleIndex = this.getStatisticsIndex(2, this.todayFilter);
        const byLocaleSingleDateIndex = this.getStatisticsIndex(2, this.singleDateFilter);
        this.statistics[byLocaleIndex] = statistics;
        this.setData(byLocaleIndex);
        this.statistics[byLocaleSingleDateIndex] = statistics;
        this.setData(byLocaleSingleDateIndex);
      }
    } catch (e) {
      this.logService.error('[MyStatisticsComponent getUserConversationStatistics:error]', e);
    }
    this.isLoading = false;
  }

  async getUserConversationStatisticsForRange() {
    this.isLoading = true;
    try {
      const index = this.getStatisticsIndex();
      const { startDate, endDate } = this.getFilterDates(index);
      const { items } = await this.apiService.getUserConversationStatisticsForRange({
        userId: this.userId,
        startDate,
        endDate,
      });

      this.rangeStatistics[index] = items;

      this.logService.log('[MyStatisticsComponent getUserConversationStatisticsForRange:statistics]', items);
    } catch (e) {
      this.logService.error('[MyStatisticsComponent getUserConversationStatisticsForRange:error]', e);
    }
    this.isLoading = false;
  }

  setPointsData(index: string) {
    this.points[index] = [
      {
        name: this.conversationPointsTitle,
        value: _.get(this.statistics[index], 'countConversationsPoints', 0),
      },
      {
        name: this.conversationFeedbackPointsTitle,
        value: _.get(this.statistics[index], 'countConversationFeedbackPoints', 0),
      },
    ];
  }

  setStarsData(index: string) {
    this.stars[index] = [
      {
        name: this.conversationStarsTitle,
        value: _.get(this.statistics[index], 'countConversationsStars', 0),
      },
      {
        name: this.conversationFeedbackStarsTitle,
        value: _.get(this.statistics[index], 'countConversationFeedbackStars', 0),
      },
    ];
  }

  setTotalAndDuration(index: string) {
    this.totalAndDuration[index] = [
      {
        name: this.numberConversationsTitle,
        value: _.get(this.statistics[index], 'countConversations', 0),
      },
      {
        name: this.durationConversationsTitle,
        value: _.get(this.statistics[index], 'countConversationFeedbackStars', 0),
      },
    ];
  }

  private setData = (index = this.getStatisticsIndex()) => {
    if (!this.statistics[index]) {
      return;
    }
    this.setPointsData(index);
    this.setStarsData(index);
    this.setTotalAndDuration(index);
  };

  prepareCountPointsForChart = (statistics: IUserConversationStatistics, item: string) => {
    return (
      (+statistics[`countConversationsPoints${item}`] || 0) +
      (+statistics[`countConversationFeedbackPoints${item}`] || 0)
    );
  };

  prepareCountStarsForChart = (statistics: IUserConversationStatistics, item: string) => {
    return (
      (+statistics[`countConversationsStars${item}`] || 0) + (+statistics[`countConversationFeedbackStars${item}`] || 0)
    );
  };

  onDateRangeChange() {
    const index = this.getStatisticsIndex();
    if (+moment(this.dateRange.begin) > +moment(this.dateRange.end)) {
      return;
    }
    try {
      this.searchFilter[index] = {
        startDate: moment(this.dateRange.begin).format('YYYY-MM-DD'),
        endDate: moment(this.dateRange.end).format('YYYY-MM-DD'),
      };

      this.getUserConversationStatisticsForRange();
    } catch (e) {
      this.searchFilter[index] = {
        startDate: this.defaultFilter.startDate,
        endDate: this.defaultFilter.endDate,
      };
    }
  }

  onStatisticsDateChange() {
    if (!moment(this.statisticsDate).isValid()) {
      return;
    }

    this.getUserConversationStatistics(false);
  }

  getFilterDates(index: string) {
    const [, filter] = index.split(':') || [0, 0];
    if (+filter === this.todayFilter) {
      return { startDate: moment().format('YYYY-MM-DD'), endDate: '' };
    }
    if (+filter === this.singleDateFilter) {
      return { startDate: this.statisticsDate, endDate: '' };
    }
    if (this.thisMonthFilter === +filter) {
      return {
        startDate: moment().startOf('month').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
      };
    }
    if (this.dateRangeFilter === +filter) {
      return this.searchFilter[index] || this.defaultFilter;
    }

    return undefined;
  }

  changeSelectedIndexTab(tabIndex: number) {
    this.selectedTab.setValue(tabIndex);
    this.delayDraw(true);
    this[this.tabsRef[tabIndex]].realignInkBar();
  }

  delayDraw(withLoading = false) {
    this.isLoading = withLoading;
    this.isDraw = false;
    setTimeout(() => {
      this.isDraw = true;
      this.isLoading = withLoading ? false : this.isLoading;
    }, 500);
  }

  changeSelectedFilterTab($event: number) {
    this.filterSelectedTab[this.selectedTab.value].setValue($event);

    const index = this.getStatisticsIndex();

    const isSingle = [this.todayFilter, this.singleDateFilter].includes($event);
    const statisticsData = isSingle ? this.statistics[index] : this.rangeStatistics[index];

    this.delayDraw(!!statisticsData);

    if (this.selectedTab.value === 0 && $event === this.todayFilter && this.statistics[index]) {
      this.setData();
    }

    if (statisticsData) {
      return;
    }

    if (isSingle) {
      this.getUserConversationStatistics(false);
    } else {
      this.getUserConversationStatisticsForRange();
    }
  }

  getStatisticsIndex(
    selectedTabValue = this.selectedTab.value,
    filterSelectedTabValue = this.filterSelectedTab[selectedTabValue].value
  ) {
    return `${selectedTabValue}:${filterSelectedTabValue}`;
  }

  getStatisticsContext(isRange: boolean = false) {
    const index = this.getStatisticsIndex();
    return isRange
      ? { statistics: this.rangeStatistics[index] }
      : {
          statistics: this.statistics[index],
          stars: this.stars[index],
          points: this.points[index],
          totalAndDuration: this.totalAndDuration[index],
        };
  }

  isAllowToRender(filter: number, selectedTab: number = 0) {
    return (
      this._isActive &&
      this.isDraw &&
      this.selectedTab.value === selectedTab &&
      this.filterSelectedTab[this.selectedTab.value].value === filter
    );
  }
}
