<div class="master-league-page">
  <mat-card class="ctn-card">
    <mat-card-content>
      <h5 class="ctn-h3 ctn-margin">
        <mat-icon>link</mat-icon>
        {{ 'SEND_MAIL.TITLE' | translate }}
        <button
          mat-raised-button
          color="primary"
          [title]="'BUTTON.SYNC' | translate"
          class="me-2"
          (click)="AmazonListUsers()"
          style="justify-self: flex-end"
        >
          <mat-icon>sync</mat-icon>
          {{ 'BUTTON.SYNC' | translate }}
        </button>
        <button
          mat-raised-button
          color="primary"
          [title]="'BUTTON.MAIL' | translate"
          class="me-2"
          (click)="openManageMailResponses($event)"
        >
          <mat-icon>mail</mat-icon>
          {{ 'BUTTON.MAIL' | translate }}
        </button>
      </h5>

      <hr />
      <div class="mat-elevation-z8"></div>

      <div class="d-flex row px-3">
        <mat-card class="ctn-card mt-2 col-md-12 col-sm-12 mat-card mat-card">
          <mat-card-content>
            <table mat-table [dataSource]="dataSource">
              <!-- Position Column -->
              <ng-container matColumnDef="Username">
                <th mat-header-cell *matHeaderCellDef>Username</th>
                <td mat-cell *matCellDef="let element">{{ element.Username }}</td>
              </ng-container>

              <!-- Name Column -->
              <ng-container matColumnDef="Firstname">
                <th mat-header-cell *matHeaderCellDef>Firstname</th>
                <td mat-cell *matCellDef="let element">{{ element.IdentityInfo.FirstName }}</td>
              </ng-container>

              <!-- Weight Column -->
              <ng-container matColumnDef="Lastname">
                <th mat-header-cell *matHeaderCellDef>Lastname</th>
                <td mat-cell *matCellDef="let element">{{ element.IdentityInfo.LastName }}</td>
              </ng-container>

              <!-- Symbol Column -->
              <ng-container matColumnDef="Email">
                <th mat-header-cell *matHeaderCellDef>Email</th>
                <td mat-cell *matCellDef="let element">{{ element.IdentityInfo.Email }}</td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>

            <mat-paginator
              [pageSizeOptions]="[5, 10, 20]"
              showFirstLastButtons
              aria-label="Select page of periodic elements"
            >
            </mat-paginator>
          </mat-card-content>
        </mat-card>
      </div>
    </mat-card-content>
  </mat-card>
</div>
