import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { listFadeInOut } from '@cation/core/animations';
import { ApiService } from '@cation/core/services/api/api.service';
import { ApiHook } from '@cation/core/services/api/api-hook';
import { LogService } from '@cation/core/services/log/log.service';

@Component({
  selector: 'ctn-gamification-settings',
  templateUrl: './gamification-settings.component.html',
  styleUrls: ['./gamification-settings.component.scss'],
  animations: [listFadeInOut],
})
export class GamificationSettingsComponent implements OnInit {
  starsWorth = {};
  starsCount = 0;
  callDurationWorth: { id: string; from: number; to: number; points: number }[] = [];

  levelsFormGroup: UntypedFormGroup = new UntypedFormGroup({});
  callDurationWorthFormGroup: UntypedFormGroup = new UntypedFormGroup({});
  levels: { id: string; name: string; points: number; icon: string }[] = [];
  autoApproveEmptyFeedback: boolean;
  teamMonthlyPointPool = 0;
  maxPointsAllowedInChallenge = 0;
  pointsPerCall = 0;

  errorMessage = '';

  constructor(private apiService: ApiService, private logService: LogService, public apiHook: ApiHook) {}

  ngOnInit() {
    this.getSettings();
  }

  async getSettings() {
    const settings = await this.apiService.getGamificationSettings();

    this.logService.log('[GamificationSettingsComponent getSettings]', settings);

    _.map(settings.levels, (value, key) => {
      return this.addLevel(key, value.name, value.points, value.icon);
    });

    _.map(settings.callDurationWorthPoints, (value, key) => {
      if (Number(key) === _.size(settings.callDurationWorthPoints) - 1) {
        value.to = value.from + 1;
      }
      return this.addCallDurationWorth(key, value.from, value.to, value.points);
    });

    this.starsWorth = settings.starsWorth;
    this.starsCount = Object.keys(settings.starsWorth).length;
    this.autoApproveEmptyFeedback = settings.autoApproveEmptyFeedback;
    this.teamMonthlyPointPool = settings.teamMonthlyPointPool;
    this.maxPointsAllowedInChallenge = settings.maxPointsAllowedInChallenge;
    this.pointsPerCall = settings.pointsPerCall;
  }

  trackByFn(index: any, item: any) {
    return index;
  }

  onAddLevel() {
    const level = _.size(this.levelsFormGroup.controls);
    const title = `level ${level}`;

    this.addLevel(`${level}`, title, 0, '');
  }

  onAddCallDurationWorth() {
    const callDurationWorth = _.size(this.callDurationWorthFormGroup.controls);

    this.addCallDurationWorth(`${callDurationWorth}`, 0, 0, 10);
  }

  addLevel(id: string, title: string, points: number, icon: string) {
    const nameCtrl = new UntypedFormControl(title, Validators.required);
    const pointsCtrl = new UntypedFormControl(points, Validators.required);
    const iconCtrl = new UntypedFormControl(icon, Validators.required);

    this.levels.push({ id, name: title, points, icon });

    this.levelsFormGroup.addControl(
      id,
      new UntypedFormGroup({
        name: nameCtrl,
        points: pointsCtrl,
        icon: iconCtrl,
      })
    );
    this.onPointsChange(id);
  }

  addCallDurationWorth(id: string, from: number, to: number, points: number) {
    const fromCtrl = new UntypedFormControl(from, Validators.required);
    const toCtrl = new UntypedFormControl(to, Validators.required);
    const pointsCtrl = new UntypedFormControl(points, Validators.required);

    this.callDurationWorth.push({ id, from, to, points });

    this.callDurationWorthFormGroup.addControl(
      id,
      new UntypedFormGroup({
        from: fromCtrl,
        to: toCtrl,
        points: pointsCtrl,
      })
    );

    this.onFromChange(id);
    this.oncallDurationWorthPointsChange(id);
  }

  onDeleteLevel() {
    const item = this.levels.pop();
    this.levelsFormGroup.removeControl(item.id);
  }

  onDeleteCallDurationWorth() {
    const item = this.callDurationWorth.pop();
    this.callDurationWorthFormGroup.removeControl(item.id);

    if (_.size(this.callDurationWorthFormGroup.controls) === 1) {
      const toField = this.callDurationWorthFormGroup.get('0').get('to');
      const fromField = this.callDurationWorthFormGroup.get('0').get('from');
      toField.setValue(fromField.value + 1);
    }
  }

  async onSave() {
    let callDurationWorthPoints = this.callDurationWorthFormGroup.getRawValue();

    if (Object.keys(callDurationWorthPoints).length > 0) {
      let callDurationWorthPointsMax = callDurationWorthPoints[_.size(callDurationWorthPoints) - 1];
      callDurationWorthPointsMax.to = Number.MAX_SAFE_INTEGER;
    }

    const settings = [
      { key: 'starsWorth', value: this.starsWorth },
      { key: 'levels', value: this.levelsFormGroup.getRawValue() },
      { key: 'autoApproveEmptyFeedback', value: this.autoApproveEmptyFeedback },
      { key: 'teamMonthlyPointPool', value: this.teamMonthlyPointPool },
      { key: 'maxPointsAllowedInChallenge', value: this.maxPointsAllowedInChallenge },
      { key: 'callDurationWorthPoints', value: callDurationWorthPoints },
      { key: 'pointsPerCall', value: this.pointsPerCall },
    ];
    this.logService.log('[GamificationSettingsComponent onSave]', settings);

    await this.apiService.setGamificationSettings(settings);
  }

  onPointsChange(id: string) {
    const fieldId = +id;
    const field = this.levelsFormGroup.get(id).get('points');
    if (fieldId > 0) {
      const prevField = this.levelsFormGroup.get(`${fieldId - 1}`).get('points');

      if (field.value <= prevField.value) {
        field.setValue(prevField.value + 1);
      }
    }
    if (fieldId < _.size(this.levelsFormGroup.controls) - 1) {
      const nextField = this.levelsFormGroup.get(`${fieldId + 1}`).get('points');

      if (field.value >= nextField.value) {
        field.setValue(nextField.value - 1);
      }
    }
  }

  onFromChange(id: string) {
    const fieldId = +id;
    const fromField = this.callDurationWorthFormGroup.get(id).get('from');

    if (fieldId > 0) {
      const prevToField = this.callDurationWorthFormGroup.get(`${fieldId - 1}`).get('to');

      if (fromField.value <= prevToField.value || fromField.value > prevToField.value + 1) {
        fromField.setValue(prevToField.value + 1);
      }
    }

    this.onToChange(id);
  }

  onToChange(id: string) {
    const fieldId = +id;
    const fromField = this.callDurationWorthFormGroup.get(id).get('from');
    const toField = this.callDurationWorthFormGroup.get(id).get('to');

    if (toField.value <= fromField.value) {
      toField.setValue(fromField.value + 1);
    }

    if (fieldId < _.size(this.callDurationWorthFormGroup.controls) - 1) {
      const nextFromField = this.callDurationWorthFormGroup.get(`${fieldId + 1}`).get('from');
      if (toField.value >= nextFromField.value || toField.value < nextFromField.value - 1) {
        this.onFromChange(`${fieldId + 1}`);
      }
    }
  }

  oncallDurationWorthPointsChange(id: string) {
    const fieldId = +id;
    const field = this.callDurationWorthFormGroup.get(id).get('points');

    if (fieldId > 0) {
      const prevField = this.callDurationWorthFormGroup.get(`${fieldId - 1}`).get('points');

      if (prevField.value !== null && (field.value >= prevField.value || field.value === null)) {
        field.setValue(Math.max(prevField.value - 1, 0));
      }
    }

    if (fieldId < _.size(this.callDurationWorthFormGroup.controls) - 1) {
      this.oncallDurationWorthPointsChange(`${fieldId + 1}`);
    }
  }

  onLevelIconSelect(id: string, icon: string) {
    const field = this.levelsFormGroup.get(id).get('icon');
    field.setValue(icon);
  }
}
