<div class="sidenav-container">
  <!-- Status -->
  <!--<button *ngIf="auth.isAgent()" class="status-btn" [class.status-btn_active]="isOnline" (click)="toggleStatus($event)">
    <span *ngIf="isOnline">online</span>
    <span *ngIf="!isOnline">offline</span>
  </button> UI_CODE_CLEANUP-->

  <!-- Team icon -->
  <a *ngIf="auth.userProfile?.team?.id" routerLink="/profile" [matTooltip]="'BUTTON.MY_PROFILE' | translate">
    <div class="navbar-team-icon">
      <app-s3-image [isRounded]="true" [image]="auth.userProfile?.team?.icon"></app-s3-image>
    </div>
  </a>

  <!-- Notifications -->
  <button
    mat-icon-button
    type="button"
    [matTooltip]="countUnreadNotifications + ' ' + ('NAVBAR.NOTIFICATIONS' | translate)"
    (click)="openUserNotificationsDialog()"
  >
    <mat-icon *ngIf="!countUnreadNotifications">notifications</mat-icon>
    <mat-icon *ngIf="countUnreadNotifications" class="notifications-active">notifications_active</mat-icon>
  </button>

  <!-- Points -->
  <div class="pointers-dropdown" ngbDropdown placement="right">
    <button
      type="button"
      id="dropdownPoints"
      ngbDropdownToggle
      [matTooltip]="('NAVBAR.SCORE_POINTS' | translate) + ' : ' + (auth.rewards?.score || 0)"
    >
      <mat-icon>bubble_chart</mat-icon>
    </button>
    <div class="dropdown-menu" ngbDropdownMenu aria-labelledby="dropdownPoints">
      <p>Points</p>
      <div>
        <mat-icon>bubble_chart</mat-icon>
        {{ auth.rewards?.score || 0 }}
      </div>
      <div>
        <span class="user-points-title">{{ 'NAVBAR.WEEKLY_POINTS' | translate }}</span>
        {{ auth.rewards?.weeklyPoints || 0 }}
      </div>
      <div>
        <span class="user-points-title">{{ 'NAVBAR.MONTHLY_POINTS' | translate }}</span>
        {{ auth.rewards?.monthlyPoints || 0 }}
      </div>
    </div>
  </div>
</div>
