import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ApiHook } from '@cation/core/services/api/api-hook';
import { LogService } from '@cation/core/services/log/log.service';
import { S3Service } from '@cation/core/services/s3/s3.service';
import { v4 as uuid } from 'uuid';
import { ApiService } from '@cation/core/services/api/api.service';
import { ConnectService } from '@cation/core/services/amazonconnect/connect.service';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'ctn-send-mail',
  templateUrl: './send-mail.component.html',
  styleUrls: ['./send-mail.component.scss'],
})
export class SendMailComponent implements OnInit {
  editorContent: string = '';
  public quillModules = {
    toolbar: {
      container: [
        [
          'bold',
          'italic',
          'underline',
          'strike',
          'blockquote',
          'code-block',
          { header: 1 },
          { header: 2 },
          { list: 'ordered' },
          { list: 'bullet' },
          { script: 'sub' },
          { script: 'super' },
          { indent: '-1' },
          { indent: '+1' },
          { direction: 'rtl' },
          { size: ['small', false, 'large', 'huge'] },
          { header: [1, 2, 3, 4, 5, 6, false] },
          { color: [] },
          { background: [] },
          { font: [] },
          { align: [] },
          'clean',
          'link',
          'image',
          'video',
          'file',
        ],
      ],
      handlers: { file: () => {} },
    },
  };
  connectResult: any;
  uploadPercent = 100;
  private quillInstance;
  selectedEmail: string;
  emaillists = new FormControl('');
  usermailIds: string[] = [];
  // usermailIds = []
  public mail: any = {};
  constructor(
    public dialogRef: MatDialogRef<SendMailComponent>,
    public apiHook: ApiHook,
    private logService: LogService,
    private s3Service: S3Service,
    private api: ApiService,
    private connectService: ConnectService
  ) {}

  ngOnInit(): void {
    this.editorContent = `
    <h1>Welcome to Our Gamification Services</h1>
    <p>Dear User,</p>
    <p>We are glad to have you with us. Below are the details of our gamification service:</p>
    <ul>
      <li>Feature 1: Challenges</li>
      <li>Feature 2: Wagers</li>
      <li>Feature 3: Administration</li>
    </ul>
    <p>Best regards,</p>
    <p>Cation Consulting</p>
  `;
    this.connectResult = this.connectService.getData();
    console.log('connectResultconnectResult', this.connectResult);
    this.getAllEmails();

    // const usermailIds = []
    // for (const user of this.connectResult) {
    //   this.usermailIds.push(user.IdentityInfo.Email)
    // }
    // console.log("usermailIds",this.usermailIds)
  }
  onEmailChange(event: any): void {
    this.selectedEmail = event.value;
    console.log('Selected Email:', this.selectedEmail);
    // You can perform any other actions you need here
  }

  onClose($event: MouseEvent) {
    $event.preventDefault();
    $event.stopPropagation();
    this.dialogRef.close();
  }

  getUploadImageConfig = () => {
    return Promise.resolve({
      key: `img/${uuid()}`,
      level: 'public',
      progressCallback: (progress) => (this.uploadPercent = Math.floor((progress.loaded * 100) / progress.total)),
    });
  };

  getUploadFileConfig = () => {
    return Promise.resolve({
      key: `file/${uuid()}`,
      level: 'public',
      progressCallback: (progress) => (this.uploadPercent = Math.floor((progress.loaded * 100) / progress.total)),
    });
  };

  onQuillEditorCreated(quillInstance) {
    this.logService.log('onQuillEditorCreated');
    this.quillInstance = quillInstance;

    const uploadImageHandler = this.s3Service.makeQuillImageHandler(this.quillInstance, this.getUploadImageConfig);
    this.quillInstance.getModule('toolbar').addHandler('image', uploadImageHandler);

    const uploadFileHandler = this.s3Service.makeQuillFileHandler(this.quillInstance, this.getUploadFileConfig);
    this.quillInstance.getModule('toolbar').addHandler('file', uploadFileHandler);

    const button = this.quillInstance.getModule('toolbar').container.querySelector('button.ql-file');

    button.style = `${button.style}; padding: 0;`;
    const attachFileIcon = document.getElementById('input_attach_file_icon');
    button.appendChild(attachFileIcon);
  }

  public onImagePaste(e) {
    this.s3Service.uploadImageFromBuffer(e, this.quillInstance, this.getUploadImageConfig);
  }
  async SendMail() {
    const users = {
      fromAddress: 'harishn@cationeng.com',
      replyToAddress: 'harishn@cationeng.com',
      toAddresses: this.selectedEmail,
      subject: 'Parly Gamification',
      messageHtml: this.editorContent,
    };
    const email = this.api.sendMail(users).toPromise();
    this.dialogRef.close();
    return email;
  }

  async getAllEmails() {
    const data: any = await this.api.getUsers().toPromise();
    this.logService.log('getEmails:data', data);
    const useremails = [];

    data.Users.forEach((user) => {
      this.usermailIds.push(user.Attributes[0].Value);
    });
    console.log('useremailsuseremails', this.usermailIds);
    return this.usermailIds;
  }
  onContentChange() {
    // Handle the content change here
    console.log('Editor content updated:', this.editorContent);
  }
}
