import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ManageCannedResponsesDialogComponent } from '@cation/admin/components/manage-canned-responses-dialog/manage-canned-responses-dialog.component';
import { ConfirmationDialogComponent } from '@cation/core/dialogs/confirmation-dialog/confirmation-dialog.component';
import { AlertDialogComponent } from '@cation/core/dialogs/alert-dialog/alert-dialog.component';
import { FormDialogComponent } from '@cation/core/dialogs/form-dialog/form-dialog.component';
import { ComponentsModule } from '@cation/core/modules/components.module';
import { MaterialModule } from '@cation/core/modules/material.module';
import { SharedModule } from '@cation/core/modules/shared.module';
import { PromptDialogComponent } from '../core/dialogs/prompt-dialog/prompt-dialog.component';
import { AdminRoutingModule } from './admin-routing.module';
import { AddUserComponent } from './components/add-user/add-user.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ManageUserComponent } from './components/manage-user/manage-user.component';
import { ManageTeamComponent } from './components/manage-team/manage-team.component';
import { ReportsComponent } from './components/reports/reports.component';
import { GamificationSettingsComponent } from './components/gamification-settings/gamification-settings.component';
import { GamificationAchievementsComponent } from './components/gamification-achievements/gamification-achievements.component';
import { AddEditAchievementComponent } from './components/add-edit-achievement/add-edit-achievement.component';
import { AddEditTeamDialogComponent } from './components/add-edit-team-dialog/add-edit-team-dialog.component';
import { ConversationSearchComponent } from './components/conversation-search/conversation-search.component';
import { ReviewUserComponent } from './components/review-user/review-user.component';
import { TeamsStatisticsComponent } from './components/teams-statistics/teams-statistics.component';
import { ConversationQueueComponent } from './components/conversation-queue/conversation-queue.component';
import { QueueHistoryComponent } from './components/queue-history/queue-history.component';
import { AppSettingsComponent } from './components/app-settings/app-settings.component';
import { AgentsAuditComponent } from './components/agents-audit/agents-audit.component';
import { TeamMembersListComponent } from './components/team-members-list/team-members-list.component';
import { AmazonConnectSendMailComponent } from './components/amazon-connect-send-mail/amazon-connect-send-mail.component';
import { SendMailComponent } from './components/amazon-connect-send-mail/send-mail/send-mail.component';

@NgModule({
  imports: [CommonModule, SharedModule, MaterialModule, AdminRoutingModule, ComponentsModule],
  declarations: [
    ManageCannedResponsesDialogComponent,
    DashboardComponent,
    PromptDialogComponent,
    AlertDialogComponent,
    ConfirmationDialogComponent,
    FormDialogComponent,
    ManageUserComponent,
    AddUserComponent,
    ManageTeamComponent,
    AddEditAchievementComponent,
    ReportsComponent,
    GamificationSettingsComponent,
    GamificationAchievementsComponent,
    AddEditTeamDialogComponent,
    ConversationSearchComponent,
    ReviewUserComponent,
    TeamsStatisticsComponent,
    ConversationQueueComponent,
    QueueHistoryComponent,
    AppSettingsComponent,
    AgentsAuditComponent,
    TeamMembersListComponent,
    AmazonConnectSendMailComponent,
    SendMailComponent,
  ],
  // entryComponents: [
  //   PromptDialogComponent,
  //   AlertDialogComponent,
  //   ConfirmationDialogComponent,
  //   FormDialogComponent,
  //   AddUserComponent,
  //   AddEditAchievementComponent,
  //   AddEditTeamDialogComponent,
  //   ManageCannedResponsesDialogComponent
  // ]
})
export class AdminModule {
  static forRoot(): ModuleWithProviders<AdminModule> {
    return {
      ngModule: AdminModule,
      providers: [],
    };
  }
}
