export interface ISettingsLevel {
  name: string;
  icon: string;
  points: number;
}

export interface ISettingsCallDurationWorthPoints {
  from: number;
  to: number;
  points: number;
}

export interface ISettings {
  levels: { [prop: string]: ISettingsLevel };
  starsWorth: { [prop: string]: number };
  autoApproveEmptyFeedback: boolean;
  teamMonthlyPointPool: number;
  maxPointsAllowedInChallenge: number;
  callDurationWorthPoints: { [prop: string]: ISettingsCallDurationWorthPoints };
  pointsPerCall: number;
}
