export const environment = {
  production: true,
  conversationHistoryUrl: 'https://api.community.inator.cloud/bot/session/conversation',
  aiAdviceUrl: 'https://api.community.inator.cloud/agent/chat',
  aiAdviceAutocompleteUrl: 'https://api.community.inator.cloud/agent/autocomplete',
  // TODO: Use cloudfront URL
  // apiUrl: 'https://engage.community.inator.cloud/api/adm',
  apiUrl: 'https://bhgzbxj0e7.execute-api.eu-west-1.amazonaws.com/prod/api/adm',
  apiKey: 'b2DkfxTu9v53E3msv7JYqapQt38gyhjt41JNztJ1',
  googleMapApiKey: 'AIzaSyAD7v9KMq7NiT_6dP7AvPPVMFFtd5wL4FE',
  cacheApi: ['/cannedresponses'],
  autoRefreshDashboard: false,

  amplifyConfig: {
    aws_project_region: 'eu-west-1',
    aws_cognito_identity_pool_id: 'eu-west-1:e2f5deb2-ed88-4165-969d-aa5fa9cec88a',
    aws_cognito_region: 'eu-west-1',
    aws_user_pools_id: 'eu-west-1_D7T74j2TV',
    aws_user_pools_web_client_id: '6do7rismp6djik3pfl6vr6adfa',
    oauth: {},
    aws_appsync_graphqlEndpoint: 'https://6em4nlgycreaddmumjwn26i2gi.appsync-api.eu-west-1.amazonaws.com/graphql',
    aws_appsync_region: 'eu-west-1',
    aws_appsync_authenticationType: 'AWS_IAM',
    Storage: {
      AWSS3: {
        // TODO: Add functionality to use another domain name
        bucket: 'asset.gamification.community.inator.cloud',
        region: 'eu-west-1',
      },
    },
  },

  internalChatConfig: {
    appsync: {
      url: 'https://aa5cgsttmra3vk2kqsiem3fb44.appsync-api.eu-west-1.amazonaws.com/graphql',
      region: 'eu-west-1',
    },
    s3: {
      bucket: 'asset.gamification.community.inator.cloud',
      region: 'eu-west-1',
    },
    production: true,
  },
};
