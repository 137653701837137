<ngx-loading [show]="apiHook.isLoading()"></ngx-loading>

<div class="header">
  <h1 mat-dialog-title>
    {{ 'SEND_MAIL.SEND_MAIL' | translate }}
  </h1>

  <div class="me-4">
    <button mat-raised-button class="btn-save" (click)="SendMail()">
      <mat-icon>send</mat-icon>
      {{ 'BUTTON.SEND' | translate }}
    </button>
    <div class="btn-separator"></div>
    <button mat-raised-button class="btn-cancel" (click)="onClose($event)">
      {{ 'BUTTON.CANCEL' | translate }}
    </button>
  </div>
</div>
<mat-form-field class="ctn-user-form">
  <mat-select
    multiple
    [formControl]="emaillists"
    [placeholder]="'SEND_MAIL.SELECT_EMAILS_PLACEHOLDER' | translate"
    [(value)]="selectedEmail"
    (selectionChange)="onEmailChange($event)"
  >
    <mat-option *ngFor="let usermail of usermailIds" [value]="usermail">
      {{ usermail }}
    </mat-option>
  </mat-select>
</mat-form-field>

<div mat-dialog-content class="content-box">
  <div class="mb-1">
    <p class="text-muted text-center">
      {{ 'SEND_MAIL.BODY_OF_THE_MAIL' | translate }}
    </p>
    <quill-editor
      [modules]="quillModules"
      [styles]="{ 'min-height': '250px' }"
      (onEditorCreated)="onQuillEditorCreated($event)"
      [(ngModel)]="editorContent"
      (ngModelChange)="onContentChange()"
      name="email_body"
    >
    </quill-editor>
    <mat-icon id="input_attach_file_icon">attachment</mat-icon>
  </div>
</div>
