<section>
  <div class="header">
    <div class="ctn-title">
      <h1 class="title">{{ 'SCORE_HISTORY.TITLE' | translate }}</h1>
    </div>
    <span class="balance"
      >{{ 'SCORE_HISTORY.CURRENT_BALANCE' | translate }}:
      <span class="score">{{ authHelper?.rewards?.score }}</span>
    </span>
  </div>

  <div class="ctn-content" #ctnContainer>
    <div *ngIf="initLoading" class="loader" [@fadeInOut]>
      <mat-spinner mode="indeterminate"></mat-spinner>
    </div>

    <table>
      <thead>
        <tr>
          <th>{{ 'SCORE_HISTORY.STATUS_TITLE' | translate }}</th>
          <th>{{ 'SCORE_HISTORY.DATE_TITLE' | translate }}</th>
          <th>{{ 'SCORE_HISTORY.POINTS' | translate }}</th>
          <th>{{ 'SCORE_HISTORY.DETAILS_TITLE' | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let event of events; let i = index; trackBy: trackItem" [@listFadeInOut]="events.length">
          <ng-container *ngTemplateOutlet="eventItemTemplate; context: Object.assign(event, { index: i })">
          </ng-container>
        </tr>
      </tbody>
    </table>

    <p *ngIf="!events || !events.length" class="empty-message">{{ 'SCORE_HISTORY.NO_POINTS' | translate }}</p>

    <div class="loader-more" #loading>
      <span *ngIf="events && events.length && isLoading"> {{ 'GENERAL.LOADING' | translate }}... </span>
    </div>
  </div>
</section>

<ng-template #eventItemTemplate let-index="index" let-id="id" let-type="type" let-score="score" let-data="data">
  <td>
    <span class="score-history__item__status">
      {{ 'SCORE_HISTORY.' + data?.eventType + '_TYPE_DESCRIPTION' | translate }}
    </span>
  </td>
  <td>
    <span class="score-history__item__date">
      {{
        getDateFromId(id)
          | date
            : (['CONVERSATION_FEEDBACK', 'CONVERSATION_STAR_RATING'].includes(data?.eventType)
                ? 'dd MMM yyyy'
                : 'dd MMM yyyy HH:mm')
      }}
    </span>
  </td>
  <td
    [class.score-history__item__winner]="type === EVENT_TYPE.USER.ADD_SCORE"
    [class.score-history__item__looser]="type === EVENT_TYPE.USER.SPEND_SCORE || data?.eventType.endsWith('_LOST')"
    class="text-center"
  >
    {{ type === EVENT_TYPE.USER.ADD_SCORE ? '+' : '-' }}{{ data?.score }}
  </td>
  <td>
    <div [ngSwitch]="data?.eventType">
      <ng-container *ngSwitchCase="isChallenge(data?.eventType) ? data?.eventType : ''">
        <ng-container *ngTemplateOutlet="eventTypeChallengeTemplate; context: data"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="isWager(data?.eventType) ? data?.eventType : ''">
        <ng-container *ngTemplateOutlet="eventTypeChallengeWagerTemplate; context: data"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'CONVERSATION_FEEDBACK'">
        <ng-container *ngTemplateOutlet="eventTypeConversationFeedbackTemplate; context: data"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'CONVERSATION_STAR_RATING'">
        <ng-container *ngTemplateOutlet="eventTypeConversationStarRatingTemplate; context: data"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'ADD_ACHIEVEMENT'">
        <ng-container *ngTemplateOutlet="eventTypeAddAchievementTemplate; context: data"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'CALL_DURATION_POINTS'">
        <ng-container *ngTemplateOutlet="eventTypeCallDurationsTemplate; context: data"></ng-container>
      </ng-container>

      <span *ngSwitchDefault>{{ data?.eventType }}</span>
    </div>
  </td>
</ng-template>

<ng-template #eventTypeChallengeTemplate let-challenge="challenge">
  <span
    class="score-history__item__description"
    [innerHTML]="'SCORE_HISTORY.CHALLENGE_DESCRIPTION' | translate: getChallengeOpponent(challenge)"
  ></span>
</ng-template>

<ng-template #eventTypeConversationFeedbackTemplate let-conversation="conversation">
  <span class="score-history__item__description">
    {{ 'SCORE_HISTORY.CONVERSATION_FEEDBACK_DESCRIPTION' | translate }}
  </span>
</ng-template>

<ng-template #eventTypeCallDurationsTemplate let-duration="duration">
  <span class="score-history__item__description">
    {{ 'SCORE_HISTORY.CALL_DURATION_POINTS_DESCRIPTION' | translate }}
  </span>
</ng-template>

<ng-template #eventTypeConversationStarRatingTemplate let-conversation="conversation">
  <span class="score-history__item__description">
    {{ 'SCORE_HISTORY.CONVERSATION_STAR_RATING_DESCRIPTION' | translate }}
  </span>
</ng-template>

<ng-template #eventTypeAddAchievementTemplate let-achievement="achievement" let-description="description">
  <div class="d-flex flex-column">
    <div class="d-flex flex-row align-items-center">
      <div
        class="me-2 score-history__item__icon"
        *ngIf="achievement?.icon"
        [matTooltip]="description || achievement?.description"
      >
        <app-s3-image [image]="achievement?.icon"></app-s3-image>
      </div>
      <span class="score-history__item__description">"{{ description || achievement?.description }}"</span>
    </div>
  </div>
</ng-template>

<ng-template #eventTypeChallengeWagerTemplate let-challengeWager="challengeWager">
  <span
    class="score-history__item__description"
    [innerHTML]="
      'SCORE_HISTORY.CHALLENGE_WAGER_VOTE_DESCRIPTION'
        | translate
          : (challengeWager.initiatorId === authHelper.userProfile.cognitoId
              ? challengeWager.initiatorVote
              : challengeWager.opponentVote)
    "
  ></span>
</ng-template>
