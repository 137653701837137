import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import * as moment from 'moment';
import { CMS_TYPE } from '@cation/core/enums/cms-type.enum';
import { LogService } from '@cation/core/services/log/log.service';
import * as apiTypes from '@cation/core/types/api';
import IAchievement from '@cation/core/types/achievement';
import User from '@cation/core/types/user';
import Team from '@cation/core/types/team';
import { environment } from '../../../../environments/environment';

type Agent = User & { team: Team };

const BASE_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private cancelPendingRequests$ = new Subject<void>();

  constructor(private http: HttpClient, private logService: LogService) {
    this.logService.info('-------ApiService--------');
  }

  /** Cancels all pending Http requests. */
  public cancelPendingRequests() {
    this.cancelPendingRequests$.next();
  }

  public onCancelPendingRequests() {
    return this.cancelPendingRequests$.asObservable();
  }

  /**** Conversation API ****/
  disableHumanMode(body) {
    return this.http.put(`${BASE_URL}/humanmode/disable`, body);
  }

  /**** Video Chat API ****/
  startVideoChat(body) {
    return this.http.post(`${BASE_URL}/video-chat/start`, body);
  }

  endVideoChat(body) {
    return this.http.post(`${BASE_URL}/video-chat/end`, body);
  }

  /**** Canned Response API ****/
  getCannedResponses() {
    return this.http.get(`${BASE_URL}/cannedresponses`);
  }

  putCannedresponses(cannedResponses) {
    return this.http.put(`${BASE_URL}/cannedresponses`, cannedResponses);
  }

  deleteCannedresponses(cannedResponses) {
    return this.http.put(`${BASE_URL}/cannedresponses/delete`, cannedResponses);
  }

  /**** CONVERSATION ****/
  transferConversation({ conversationId, ...payload }) {
    return this.http.post(`${BASE_URL}/conversation/${conversationId}/transfer`, payload);
  }

  getConversationsByUserId(userId: string) {
    return this.http.get(`${BASE_URL}/conversation/${userId}`);
  }

  findSuitableConversation(userId: string) {
    return this.http.get(`${BASE_URL}/suitable-conversation/${userId}`);
  }

  closeConversation(conversationId: string, agentId: string) {
    return this.http.post(`${BASE_URL}/conversation/${conversationId}/close`, { agentId });
  }

  closeAllConversations(): Observable<{ status: string; time: number }> {
    return this.http.post<{ status: string; time: number }>(`${BASE_URL}/conversations/close`, {});
  }

  getAppSettings(): Observable<apiTypes.IAppSettings> {
    return this.http.get<apiTypes.IAppSettings>(`${BASE_URL}/settings`);
  }

  setAppSettings(settings) {
    return this.http.put(`${BASE_URL}/settings`, settings);
  }

  inviteAgentInConversation(conversationId: string, agentId: string) {
    console.warn({ conversationId, agentId });
    return this.http.post(`${BASE_URL}/conversation/${conversationId}/invite`, { agentId });
  }

  sendTypingStatus(body) {
    return this.http.put(`${BASE_URL}/agent/typing-status`, body).toPromise();
  }

  /**** CMS API ****/
  changeCoreCms({ id, conversationId, type }) {
    return this.http.post(`${BASE_URL}/cms/ticket/${id}/core`, {
      conversationId,
      type,
    });
  }

  sendToCms(data) {
    return this.http.post(`${BASE_URL}/cms/ticket`, data);
  }

  getCmsById(id, type: CMS_TYPE) {
    return this.http.get(`${BASE_URL}/cms/ticket/${id}?type=${type}`);
  }

  getCmsProfile(userId, type: CMS_TYPE) {
    return this.http.get(`${BASE_URL}/cms/user/${userId}?type=${type}`);
  }

  searchCms(filter: string, type: CMS_TYPE) {
    return this.http.get(`${BASE_URL}/cms/ticket/search?filter=${filter}&type=${type}`);
  }

  attachCmsToConversation(conversationId, ticketId, type: CMS_TYPE) {
    return this.http.post(`${BASE_URL}/cms/ticket/${ticketId}/attach`, {
      conversationId,
      type,
    });
  }

  addCommentToCms({ id, ...payload }, type: CMS_TYPE) {
    return this.http.post(`${BASE_URL}/cms/ticket/${id}/comments`, {
      ...payload,
      type,
    });
  }

  getListCommentsByCms(id, type: CMS_TYPE) {
    return this.http.get(`${BASE_URL}/cms/ticket/${id}/comments?type=${type}`);
  }

  getListAgents(body = {}): Promise<Agent[]> {
    const params = new HttpParams({ fromObject: body });
    return this.http.get<Agent[]>(`${BASE_URL}/agents/search?${params}`).toPromise();
  }
  /**** Send Mail ****/
  sendMail(user) {
    return this.http.post(`${BASE_URL}/send-mail`, user);
  }

  /**** Amazon connect ****/
  amazonListUsers() {
    return this.http.get(`${BASE_URL}/amazon-connect/users`);
  }

  /**** Cognito API ****/
  getUsers() {
    return this.http.get(`${BASE_URL}/cognito/users`);
  }

  addUser(user) {
    return this.http.post(`${BASE_URL}/cognito/user`, user);
  }

  deleteUser(user) {
    return this.http.put(`${BASE_URL}/cognito/user/delete`, user);
  }

  disableUser(user) {
    return this.http.put(`${BASE_URL}/cognito/user/disable`, user);
  }

  enableUser(user) {
    return this.http.put(`${BASE_URL}/cognito/user/enable`, user);
  }

  getUserGroups(username) {
    return this.http.get(`${BASE_URL}/cognito/user/${username}/group`);
  }

  getGroups() {
    return this.http.get(`${BASE_URL}/cognito/groups`);
  }

  updateUserAttributes(user) {
    return this.http.put(`${BASE_URL}/cognito/user/attribues`, user);
  }

  addUserToGroup(user) {
    return this.http.post(`${BASE_URL}/cognito/user/group`, user);
  }

  removeUserFromGroup(user) {
    return this.http.put(`${BASE_URL}/cognito/user/group/delete`, user);
  }

  getKeywords() {
    return this.http.get<{ PrimaryTopic: string }[]>(`${BASE_URL}/agent/keywords`);
  }

  updateAgent(body) {
    return this.http.put(`${BASE_URL}/agent`, body);
  }

  setUserPassword(user) {
    return this.http.put(`${BASE_URL}/cognito/user/password`, user);
  }

  /**** Reports API ****/
  getReportEmbedUrl(reportName) {
    return this.http.get(`${BASE_URL}/report/embedurl/${reportName}`);
  }

  /** Similar Cases */

  /**
   * Method for finding similar cases (conversations)
   *
   * @param {Object} body
   * @param {Number} body.maxResult
   * @param {String} body.message
   */
  findSimilarCases(body): Observable<apiTypes.IESConversationSearchResponse> {
    return this.http.post<apiTypes.IESConversationSearchResponse>(`${BASE_URL}/conversation/search`, body);
  }

  /** GAMIFICATION */
  getRewards(): Promise<apiTypes.IChallengeMember> {
    return this.http.get<apiTypes.IChallengeMember>(`${BASE_URL}/rewards`).toPromise();
  }

  getUserAchievements(userId: string): any {
    return this.http.get(`${BASE_URL}/gamification/user/${userId}/achievements`).toPromise();
  }

  getGamificationSettings(): Promise<apiTypes.ISettings> {
    return this.http.get<apiTypes.ISettings>(`${BASE_URL}/gamification/settings`).toPromise();
  }

  setGamificationSettings(settings): any {
    return this.http.put(`${BASE_URL}/gamification/settings`, settings).toPromise();
  }

  getAchievements(): Promise<IAchievement[]> {
    return this.http.get<IAchievement[]>(`${BASE_URL}/gamification/achievements`).toPromise();
  }

  saveAchievement(achievement): Promise<IAchievement> {
    return this.http.put<IAchievement>(`${BASE_URL}/gamification/achievement`, achievement).toPromise();
  }

  deleteAchievement(achievementId: string): Promise<IAchievement> {
    return this.http.delete<IAchievement>(`${BASE_URL}/gamification/achievement/${achievementId}`).toPromise();
  }

  addUserAchievement(achievement): Promise<IAchievement> {
    return this.http.post<IAchievement>(`${BASE_URL}/gamification/reward`, achievement).toPromise();
  }

  /** GAMIFICATION CHALLENGES  */

  findGamificationChallenges(options): Promise<apiTypes.IChallengesResponse> {
    const params = new HttpParams({ fromObject: options });

    return this.http.get<apiTypes.IChallengesResponse>(`${BASE_URL}/gamification/challenge?${params}`).toPromise();
  }

  findGamificationChallengeWagers(options): Promise<apiTypes.IChallengeWagersResponse> {
    const params = new HttpParams({ fromObject: options });

    return this.http
      .get<apiTypes.IChallengeWagersResponse>(`${BASE_URL}/gamification/challenge-wager?${params}`)
      .toPromise();
  }

  addGamificationChallenge(challenge): Promise<apiTypes.IChallengeItem> {
    return this.http.post<apiTypes.IChallengeItem>(`${BASE_URL}/gamification/challenge`, challenge).toPromise();
  }

  addGamificationChallengeWager(challengeWager): Promise<apiTypes.IChallengeWagerItem> {
    return this.http
      .post<apiTypes.IChallengeWagerItem>(`${BASE_URL}/gamification/challenge-wager`, challengeWager)
      .toPromise();
  }

  changeGamificationChallengeStatus(challengeId, body): Promise<apiTypes.IChallengeItem> {
    return this.http
      .put<apiTypes.IChallengeItem>(`${BASE_URL}/gamification/challenge/${challengeId}`, body)
      .toPromise();
  }

  changeGamificationChallengeWagerStatus(challengeWagerId, body): Promise<apiTypes.IChallengeWagerItem> {
    return this.http
      .put<apiTypes.IChallengeWagerItem>(`${BASE_URL}/gamification/challenge-wager/${challengeWagerId}`, body)
      .toPromise();
  }

  getGamificationMemberInfo(memberType, memberId): Promise<apiTypes.IStatisticsMember> {
    const params = new HttpParams({ fromObject: { memberType, memberId } });

    return this.http.get<apiTypes.IStatisticsMember>(`${BASE_URL}/gamification/member?${params}`).toPromise();
  }

  findAudits(body): Promise<apiTypes.IAuditsResponse> {
    const params = new HttpParams({ fromObject: body });

    return this.http.get<apiTypes.IAuditsResponse>(`${BASE_URL}/audit?${params}`).toPromise();
  }

  findActivityTypes() {
    return this.http.get(`${BASE_URL}/audit/activity-types`).toPromise();
  }

  /** FEEDBACK COMMENTS */
  findFeedbackCommentsByAgentId(agentId: string, body): Promise<apiTypes.IFeedBackResponse> {
    const params = new HttpParams({ fromObject: body });

    return this.http.get<apiTypes.IFeedBackResponse>(`${BASE_URL}/user/${agentId}/feedback?${params}`).toPromise();
  }

  findFeedbackComments(body): Promise<apiTypes.IFeedBackResponse> {
    return this.http.post<apiTypes.IFeedBackResponse>(`${BASE_URL}/feedback/search`, body).toPromise();
  }

  changeFeedbackComment(comment: apiTypes.IFeedBackCommentCommon) {
    return this.http.put<boolean>(`${BASE_URL}/feedback`, comment).toPromise();
  }

  getGamificationStatistics(type, range, options): Promise<apiTypes.IStatisticsResponse> {
    const params = new HttpParams({ fromObject: options });

    return this.http
      .get<apiTypes.IStatisticsResponse>(`${BASE_URL}/gamification/statistics?type=${type}&range=${range}&${params}`)
      .toPromise();
  }

  getEventsByMember(memberId, options = undefined): Promise<apiTypes.IEventsResponse> {
    const params = new HttpParams({ fromObject: options });

    return this.http
      .get<apiTypes.IEventsResponse>(`${BASE_URL}/gamification/member/${memberId}/events?${params}`)
      .toPromise();
  }

  /** USER CONVERSATION STATISTICS */
  getUserConversationStatistics({
    startDate,
    endDate,
    userId,
  }: {
    startDate: string;
    endDate?: string;
    userId?: string;
  }): Promise<apiTypes.IUserConversationStatistics> {
    const params = new HttpParams({ fromObject: { startDate, endDate, userId } });

    return this.http
      .get<apiTypes.IUserConversationStatistics>(`${BASE_URL}/user/conversation-statistics?${params}`)
      .toPromise();
  }

  getUserConversationStatisticsForRange({
    startDate,
    endDate,
    userId,
    limit = moment(endDate).diff(startDate, 'days'),
  }: {
    startDate: string;
    endDate?: string;
    userId?: string;
    limit?: number;
  }): Promise<apiTypes.IUserConversationRangeStatistics> {
    const params = new HttpParams({ fromObject: { startDate, endDate, userId, limit: `${limit}` } });

    return this.http
      .get<apiTypes.IUserConversationRangeStatistics>(`${BASE_URL}/user/conversation-statistics/range?${params}`)
      .toPromise();
  }
}
