import { Role } from '@cation/core/auth/role.enum';
import { Route } from '@angular/router';
import { ManageUserComponent } from '../../components/manage-user/manage-user.component';
import { ManageTeamComponent } from '../../components/manage-team/manage-team.component';
import { ReviewUserComponent } from '../../components/review-user/review-user.component';
import { TeamsStatisticsComponent } from '../../components/teams-statistics/teams-statistics.component';
import { AgentsAuditComponent } from '../../components/agents-audit/agents-audit.component';
import { RouteDataModel } from '@cation/core/models/route-data.model';
import { AmazonConnectSendMailComponent } from '@cation/admin/components/amazon-connect-send-mail/amazon-connect-send-mail.component';

export const teamAdminRoutesConfig: Route[] = [
  {
    path: 'manage/user',
    component: ManageUserComponent,
    data: {
      roles: [Role.ADMIN],
      label: 'NAVBAR.MANAGE_USERS_LINK',
    } as RouteDataModel,
  },
  {
    path: 'manage/team',
    component: ManageTeamComponent,
    data: {
      roles: [Role.ADMIN],
      label: 'NAVBAR.MANAGE_TEAMS_LINK',
    } as RouteDataModel,
  },
  {
    path: 'agents/audit',
    component: AgentsAuditComponent,
    data: {
      roles: [Role.ADMIN],
      label: 'NAVBAR.AGENTS_AUDIT_LINK',
    } as RouteDataModel,
  },
  {
    path: 'review/user',
    component: ReviewUserComponent,
    data: {
      roles: [Role.ADMIN],
      label: 'NAVBAR.REVIEW_USERS_LINK',
    } as RouteDataModel,
  },
  {
    path: 'teams/statistics',
    component: TeamsStatisticsComponent,
    data: {
      roles: [Role.ADMIN],
      label: 'NAVBAR.TEAMS_STATISTICS_LINK',
    } as RouteDataModel,
  },
  {
    path: 'manage/send-mail',
    component: AmazonConnectSendMailComponent,
    data: {
      roles: [Role.ADMIN],
      label: 'NAVBAR.SEND_MAIL',
    } as RouteDataModel,
  },
];
